import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Burguer } from '../../../assets/images/burguer.svg';
import DrawerChangeLanguageMobile from '../../DrawerChangeLanguageMobile';
import Login from '../../../components/Login';
import { useMemorial } from '../../../context/memorial.provider';
import { logout } from '../../../context/memorial.actions';
import { useIsMobile } from '../../../utils/hooks';
import { Dropdown, MenuProps } from 'antd';
import AvatarMemorial from '../../Avatar';
import { ReactComponent as Logo } from '../../../assets/images/logoBlueNegative.svg';

const Menu = ({
  setShowLoginModalAutomatically,
  showLoginModalAutomatically,
}: {
  setShowLoginModalAutomatically?: (value: boolean) => void;
  showLoginModalAutomatically?: boolean;
}) => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const [showDrawerChangeLanguageMobile, setShowDrawerChangeLanguageMobile] =
    useState<boolean>(false);
  const [showModalLogin, setShowModalLogin] = useState<boolean>(false);
  const { firstName, id, image, lastName, memorialDispatch } = useMemorial();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const getItemsToDropdown = (): MenuProps['items'] => {
    const items: {
      label: string;
      type: 'item' | 'group';
      to?: string;
      onClick?: () => void;
    }[] = [
      { label: t('topbar.menuTitle'), type: 'group' },
      { label: t('topbar.start'), to: id ? '/home/start?page=1&pageSize=3' : '/', type: 'item' },
    ];

    if (id) {
      items.push(
        { label: t('topbar.myMemorials'), to: '/home/memorials/list?page=1', type: 'item' },
        {
          label: t('topbar.commentsManagement'),
          to: '/home/memorials/comments-management',
          type: 'item',
        },
        {
          label: t('topbar.facebookProfileManagement'),
          to: '/home/facebook-profile',
          type: 'item',
        },
        { label: t('common.myAccount'), to: '/home/account', type: 'item' }
      );

      if (isMobile) {
        items.push({
          label: t('common.changeLanguage'),
          onClick: () => setShowDrawerChangeLanguageMobile(true),
          type: 'item',
        });
      }

      items.push({
        label: t('topbar.logout'),
        onClick: () => {
          localStorage.removeItem('memorial_token');
          memorialDispatch(logout());
          navigate('/');
        },
        type: 'item',
      });
    } else {
      items.push(
        {
          label: t('login.title'),
          onClick: () => setShowModalLogin(true),
          type: 'item',
        },
        { label: t('newMemorial.registerMemorial'), to: '/home/memorials/new', type: 'item' },
        {
          label: t('common.changeLanguage'),
          onClick: () => setShowDrawerChangeLanguageMobile(true),
          type: 'item',
        }
      );
    }

    return items.map(({ label, type, to, onClick }, index) => ({
      label: (
        <p
          className={
            (to?.includes('/home/start') && pathname.includes('/home/start')) ||
            (to?.includes('/home/memorials/list') && pathname.includes('/home/memorials/list')) ||
            pathname === to
              ? 'text-primary'
              : ''
          }
          onClick={to ? () => navigate(to) : onClick ? onClick : undefined}
        >
          {label}
        </p>
      ),
      key: index,
      type,
    }));
  };

  return (
    <div className="flex min-h-[39px] items-center justify-between bg-white px-[16px] md:px-0">
      <div className="relative w-full md:w-[initial]">
        {showDrawerChangeLanguageMobile && (
          <DrawerChangeLanguageMobile onClose={() => setShowDrawerChangeLanguageMobile(false)} />
        )}
        {(showModalLogin || showLoginModalAutomatically) && (
          <Login
            showOnlyModal
            showModal
            onCloseModal={() => {
              setShowModalLogin(false);
              setShowLoginModalAutomatically?.(false);
            }}
          />
        )}
        <div className="flex w-full items-center justify-between">
          <Dropdown
            menu={{ items: getItemsToDropdown() }}
            trigger={['click']}
            placement="bottomRight"
            overlayClassName={`dropdown-menu ${isMobile ? 'menu-mobile' : ''}`}
            overlayStyle={{ minWidth: '240px' }}
          >
            {isMobile ? (
              <Burguer />
            ) : (
              <div className="cursor-pointer">
                <AvatarMemorial
                  image={image}
                  initials={firstName ? `${firstName[0]}${lastName[0]}` : ''}
                  size={48}
                />
              </div>
            )}
          </Dropdown>
          {isMobile && (
            <>
              <Logo
                className="h-[32px]"
                onClick={() => navigate('/home/start?page=1&pageSize=3')}
              />
              <div className="w-[16px]" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Menu;
