import { lazy } from 'react';
const Home = lazy(() => import('../Home'));
const Start = lazy(() => import('../Start'));
const ContactUs = lazy(() => import('../ContactUs'));
const Dashboard = lazy(() => import('../Dashboard'));
const Memorial = lazy(() => import('../Memorial'));
const Account = lazy(() => import('../Account'));
const MyMemorials = lazy(() => import('../MyMemorials'));
const NewMemorial = lazy(() => import('../NewMemorial'));
const FacebookProfile = lazy(() => import('../FacebookProfile'));
const About = lazy(() => import('../About'));
const CommentsManagement = lazy(() => import('../CommentsManagement'));
const Terms = lazy(() => import('../Terms'));
const AddCommentForm = lazy(() => import('../../components/AddCommentForm'));
const PageNotFound = lazy(() => import('../PageNotFound'));
const UnderConstruction = lazy(() => import('../UnderConstruction'));

export interface RouteType {
  path: string;
  Component: any;
  key: string;
  withDefaultHelmet?: boolean;
  withInternalHelmet?: boolean;
  isProtected?: boolean;
  children?: {
    path: string;
    Component: any;
    key: string;
    withDefaultHelmet?: boolean;
    withInternalHelmet?: boolean;
    isProtected?: boolean;
  }[];
}

const routes: RouteType[] = [
  {
    path: '/under-construction',
    Component: UnderConstruction,
    key: 'underConstruction',
    withDefaultHelmet: true,
  },
  {
    path: '/',
    Component: Home,
    key: 'home',
  },
  {
    path: 'terms',
    Component: Terms,
    key: 'terms',
    withInternalHelmet: true,
  },
  {
    path: '*',
    Component: PageNotFound,
    key: 'pageNotFound',
    withDefaultHelmet: true,
  },
  {
    path: '/home',
    Component: Dashboard,
    key: 'dashboard',
    children: [
      {
        path: 'start',
        Component: Start,
        key: 'start',
      },
      {
        path: 'memorial/:id',
        Component: Memorial,
        key: 'memorial',
        withInternalHelmet: true,
      },
      {
        path: 'contact-us',
        Component: ContactUs,
        key: 'contactUs',
      },
      {
        path: 'about',
        Component: About,
        key: 'about',
      },
      {
        path: 'add-comment/:id',
        Component: AddCommentForm,
        key: 'addComment',
      },
      {
        path: 'memorials/new/:id?',
        Component: NewMemorial,
        key: 'start',
      },
      {
        path: 'account',
        Component: Account,
        key: 'account',
        isProtected: true,
      },
      {
        path: 'facebook-profile',
        Component: FacebookProfile,
        key: 'facebookProfile',
        isProtected: true,
      },
      {
        path: 'memorials/list',
        Component: MyMemorials,
        key: 'myMemorials',
        isProtected: true,
      },
      {
        path: 'memorials/comments-management',
        Component: CommentsManagement,
        key: 'commentsManagement',
        isProtected: true,
      },
    ],
  },
];

export const plainRoutes = routes.reduce((acc: RouteType[], route: RouteType) => {
  if (route.path === '*') return acc;
  if (route.children) {
    const children = route.children.map((child: RouteType) => ({
      ...child,
      path: `${route.path}/${child.path}`,
      key: child.key,
      Component: child.Component,
    }));
    return [...acc, ...children];
  }
  return [...acc, route];
}, []);

export default routes;
