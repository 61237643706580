import { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Alert, Col, Row, Checkbox } from 'antd';
import useStyle from './index.styles';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useTranslation } from 'react-i18next';

import useApi from '../../utils/api';
import { useMemorial } from '../../context/memorial.provider';
import { IData, getDecodedToken } from '../../context/memorial.actions';
import logoBig from '../../assets/images/logoBlueNegative.svg';
import { ReactComponent as Info } from '../../assets/images/info.svg';
import { passwordRegex } from '../../utils';
import success from '../../assets/images/successBig.png';

import { XmarkSolid } from '../../utils/icons';
import ModalWithPreview from '../../views/NewMemorial/CovertImageToGift/ModalWithPreview';
import { useCost, useIsMobile } from '../../utils/hooks';

const Login = ({
  onCloseModal,
  showModal,
  showOnlyModal,
}: {
  onCloseModal?: Function;
  showModal?: boolean;
  showOnlyModal?: boolean;
}) => {
  const [open, setOpen] = useState(showModal);
  const [form] = Form.useForm();
  const [formProducts] = Form.useForm();
  const [formEmailCode] = Form.useForm();
  const classes = useStyle();
  const [loading, setLoading] = useState(false);
  const [showDemoModal, setShowDemoModal] = useState<boolean>(false);
  const [hasErrorsFormProducts, setHasErrorsFormProducts] = useState<boolean>(true);
  const [userEmail, setUserEmail] = useState<string>('');
  const [loadingSecondaryButton, setLoadingSecondaryButton] = useState<boolean>(false);
  const [emailCode, setEmailCode] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const email = Form.useWatch('email', form);
  const code = Form.useWatch('code', formEmailCode);
  const check1FormProducts = Form.useWatch('check1', formProducts);
  const check2FormProducts = Form.useWatch('check2', formProducts);
  const check3FormProducts = Form.useWatch('check3', formProducts);
  const isMobile = useIsMobile();
  const cost = useCost();

  const [status, setStatus] = useState<
    'initial' | 'recovery' | 'newPassword' | 'success' | 'registerInStripe'
  >('initial');

  const { memorialDispatch } = useMemorial();

  const { api } = useApi();

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setStatus('initial');
      onCloseModal?.();
    }
  }, [form, onCloseModal, open]);

  const handleOk = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const submit = (values: any) => {
    const { email, password } = values;
    setLoading(true);
    api()
      .post('/login', { email, password })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem('memorial_token', response.data.data);
          const decode = jwtDecode(response.data.data);
          memorialDispatch(getDecodedToken(decode as IData));
          onCloseModal?.();
          navigate('/home/start?page=1&pageSize=3');
        }
      })
      .catch((error) => {
        if (error?.response?.status === 499) {
          setStatus('registerInStripe');
        }
      })
      .finally(() => setLoading(false));
  };

  const onFinishFormProducts = () => {
    console.log('go to stripe');
  };

  const checkValidFormProducts = () => {
    const { check1, check2 } = formProducts.getFieldsValue();

    setHasErrorsFormProducts(!check1 && !check2);
  };

  const onRecoveryPassword = () => {
    form.validateFields(['email']);
    let regex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
    if (email && regex.test(email)) {
      setLoadingSecondaryButton(true);

      api('application/json', false)
        .post('/user-recovery-password-send-code', { email })
        .then((response) => {
          if (response.status === 200) {
            setUserEmail(email);
            setStatus('recovery');
          }
        })
        .finally(() => setLoadingSecondaryButton(false));
    }
  };

  const onSubmitEmailCode = (values: any) => {
    const { code } = values;
    setLoading(true);

    api('application/json', false)
      .post('/user-recovery-password-validate-code', { email: userEmail, code })
      .then((response) => {
        if (response.status === 200) {
          formEmailCode.resetFields();
          setStatus('newPassword');
          setEmailCode(code);
        }
      })
      .finally(() => setLoading(false));
  };

  const submitNewPassword = (values: any) => {
    const { password, password2 } = values;
    setLoading(true);

    api('application/json', false)
      .post('/user-change-password-recovery', {
        email: userEmail,
        code: emailCode,
        new_password_1: password,
        new_password_2: password2,
      })
      .then((response) => {
        if (response.status === 200) {
          form.resetFields();
          setStatus('success');
        }
      })
      .finally(() => setLoading(false));
  };

  const text = (
    <>
      <span className="hidden md:block">{t('login.title')}</span>
      <span className="block md:hidden">{t('login.title2')}</span>
    </>
  );

  const getContent = () => {
    switch (status) {
      case 'initial': {
        return (
          <>
            <Form form={form} onFinish={submit} onFieldsChange={() => {}}>
              <Form.Item
                label={t('common.email')}
                name="email"
                rules={[
                  { required: true, message: String(t('common.formsErrors.email.required')) },
                  {
                    type: 'email',
                    message: String(t('common.formsErrors.email.valid')),
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('common.password')}
                name="password"
                rules={[
                  { required: true, message: String(t('common.formsErrors.password.required')) },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div className="w-100 mt-[-8px] text-left">
                <Button
                  onClick={onRecoveryPassword}
                  ghost
                  htmlType="button"
                  className="flex"
                  loading={loadingSecondaryButton}
                >
                  <p className="link">{t('login.forgotPassword')}</p>
                </Button>
              </div>
              <Button
                loading={loading}
                type="primary"
                className="mt-[40px] w-full"
                htmlType="submit"
              >
                {text}
              </Button>
            </Form>

            <div className="w-100 mt-[48px] text-center md:mt-[80px]">
              <Button
                ghost
                onClick={() => {
                  navigate('/home/memorials/new');
                  setOpen(false);
                }}
              >
                <p className="link">{t('login.orRegisterHere')}</p>
              </Button>
            </div>
          </>
        );
      }
      case 'recovery': {
        return (
          <>
            <h4 className="relative bottom-[24px] m-0 text-center font-IBMPlexSansSemiBold">
              {t('login.recoverPasswordSubTitle')}
            </h4>
            <Alert
              message={
                <span>
                  {t('login.weHaveSentCode')} <u>{userEmail}</u>
                </span>
              }
              type="info"
              icon={<Info />}
              closable
              showIcon
              closeIcon={<XmarkSolid />}
            />
            <Form form={formEmailCode} onFinish={onSubmitEmailCode} className="mt-[24px]">
              <Form.Item
                label={t('login.enterCodeByEmail')}
                name="code"
                rules={[
                  {
                    required: true,
                    message: String(t('login.requiredCode')),
                  },
                ]}
              >
                <Input placeholder={String(t('login.code'))} />
              </Form.Item>
              <Button
                loading={loading}
                type="primary"
                className={`w-full ${!code ? 'primaryDisabled' : ''}`}
                htmlType="submit"
              >
                {t('common.next')}
              </Button>
            </Form>
            <div className="flex justify-center">
              <Button
                ghost
                onClick={() => {
                  setStatus('initial');
                  formEmailCode.resetFields();
                }}
                className="mx-[auto] mb-[120px] mt-[16px]"
              >
                <p className="link">{t('common.back')}</p>
              </Button>
            </div>
          </>
        );
      }

      case 'newPassword': {
        return (
          <>
            <h5 className="relative bottom-[24px] m-0 text-center font-IBMPlexSansSemiBold">
              {t('login.newPasswordSubTitle')}
            </h5>
            <Form form={form} onFinish={submitNewPassword} className="mt-[8px]">
              <Row>
                <Col xs={24}>
                  <Form.Item
                    required
                    name="password"
                    label={t('login.enterNewPassword')}
                    rules={[
                      {
                        required: true,
                        message: String(t('common.formsErrors.password.required')),
                      },
                      {
                        pattern: passwordRegex,
                        message: String(t('common.formsErrors.password.valid')),
                      },
                    ]}
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    required
                    name="password2"
                    label={String(t('login.repeatNewPassword'))}
                    rules={[
                      {
                        required: true,
                        message: String(t('register.formsErrors.password.required')),
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(String(t('register.formsErrors.password.match')))
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password maxLength={20} />
                  </Form.Item>
                </Col>
              </Row>
              <Button loading={loading} type="primary" className="w-full" htmlType="submit">
                {t('common.next')}
              </Button>
            </Form>
            <div className="flex justify-center">
              <Button
                ghost
                onClick={() => setStatus('recovery')}
                className="mx-[auto] mb-[120px] mt-[16px]"
              >
                <p className="link">{t('common.back')}</p>
              </Button>
            </div>
          </>
        );
      }

      case 'success': {
        return (
          <div className="mt-[-24px] flex flex-col items-center">
            <img src={success} alt="" className="mx-auto mb-[24px]" />
            <h2 className="mb-[16px] mt-0">{t('login.recoverSuccessTitle')}</h2>
            <h5 className="mb-[8px] mt-0 max-w-[270px] text-center">
              {t('login.recoverSuccessText')}
            </h5>
            <Button
              loading={loading}
              type="primary"
              className="mt-[24px] w-full"
              htmlType="submit"
              onClick={() => setStatus('initial')}
            >
              {t('login.goToLogin')}
            </Button>
          </div>
        );
      }

      case 'registerInStripe': {
        return (
          <>
            <div className="mb-[24px]">
              <Alert
                message={
                  <>
                    <div className="font-IBMPlexSansBold">{t('login.finishRegisterInStripe')}</div>
                    <div>{t('common.stripeInformation')}</div>
                  </>
                }
                type="info"
                icon={<Info />}
                closable
                showIcon
                closeIcon={<XmarkSolid />}
              />
            </div>
            <Form
              autoComplete="off"
              form={formProducts}
              onFinish={onFinishFormProducts}
              scrollToFirstError
              onFieldsChange={checkValidFormProducts}
            >
              <Row gutter={[16, 0]}>
                <Col xs={24}>
                  <Form.Item
                    dependencies={['check2']}
                    name="check1"
                    valuePropName="checked"
                    label={null}
                    className="check-input"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const check2 = getFieldValue('check2');

                          if (!check2 && !value) {
                            return Promise.reject(
                              new Error(String(t('register.formsErrors.products.required')))
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Checkbox disabled={check2FormProducts || check3FormProducts}>
                      <div className="flex justify-between">
                        <p className="mb-[4px]">{t('about.costInfo.2')}</p>
                        <p className="whitespace-nowrap font-IBMPlexSansMedium">{cost[1]}</p>
                      </div>
                      <p className="smallParagraph">{t('about.costInfo.3')}</p>
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    dependencies={['check1']}
                    name="check2"
                    valuePropName="checked"
                    label={null}
                    className="check-input"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const check1 = getFieldValue('check1');

                          if (!check1 && !value) {
                            return Promise.reject(
                              new Error(String(t('register.formsErrors.products.required')))
                            );
                          } else {
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Checkbox disabled={check1FormProducts}>
                      <div className="flex justify-between">
                        <p className="mb-[4px]">{t('about.costInfo.4')}</p>
                        <p className="whitespace-nowrap font-IBMPlexSansMedium">{cost[2]}</p>
                      </div>
                      <p className="smallParagraph">{t('about.costInfo.5')}</p>
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col xs={24}>
                  <Form.Item
                    name="check3"
                    valuePropName="checked"
                    label={null}
                    className="check-input"
                  >
                    <Checkbox disabled={check1FormProducts}>
                      <div className="flex justify-between">
                        <p className="mb-[4px]">{t('about.costInfo.6')}</p>
                        <p className="whitespace-nowrap font-IBMPlexSansMedium">{cost[3]}</p>
                      </div>
                      <p className="smallParagraph">{t('about.costInfo.7')}</p>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Button
                    type="link"
                    className="relative top-[-24px] ml-[32px] h-auto text-info"
                    onClick={() => setShowDemoModal(true)}
                  >
                    <div className="text-[12.8px]">{t('newMemorial.convertToGift.seeDemo')}</div>
                  </Button>
                  <p className="smallParagraph ml-[32px]">{t('about.costInfo.8')}</p>
                </Col>
                <Col xs={24} className="mt-[56px] flex flex-col">
                  <Button
                    className={`mb-[8px] ${hasErrorsFormProducts ? 'primaryDisabled' : ''}`}
                    htmlType="submit"
                    type="primary"
                    onClick={() => {}}
                  >
                    {t('common.goToCheckout')}
                  </Button>
                  <Button onClick={handleCancel}>{t('common.cancel')}</Button>
                </Col>
              </Row>
            </Form>
          </>
        );
      }

      default: {
        return null;
      }
    }
  };

  const getTitle = () => {
    switch (status) {
      case 'initial': {
        return (
          <>
            <h1 className="mb-0 hidden md:block">{t('login.title4')}</h1>
            <h3 className="m-0 block md:hidden">{t('login.title3')}</h3>
          </>
        );
      }

      case 'recovery': {
        return (
          <>
            <h1 className="m-0 hidden md:block">{t('login.recoverPasswordTitle2')}</h1>
            <h3 className="m-0 block md:hidden">{t('login.recoverPasswordTitle2')}</h3>
          </>
        );
      }

      case 'newPassword': {
        return (
          <>
            <h1 className="m-0 hidden md:block">{t('login.recoverPasswordTitle')}</h1>
            <h3 className="m-0 block md:hidden">{t('login.recoverPasswordTitle')}</h3>
          </>
        );
      }

      case 'success': {
        return <span className="hidden" />;
      }

      case 'registerInStripe': {
        return (
          <>
            <h1 className="m-0 hidden md:block">{t('login.registerInStripe')}</h1>
            <h3 className="m-0 block md:hidden">{t('login.registerInStripe')}</h3>
          </>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      {!showOnlyModal && (
        <Button type={isMobile ? 'default' : 'primary'} onClick={() => setOpen(true)}>
          {text}
        </Button>
      )}
      <Modal
        footer={null}
        destroyOnClose
        title={getTitle()}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        className={classes.loginModal}
        wrapClassName={classes.loginModalWrapper}
        closeIcon={<XmarkSolid />}
      >
        {showDemoModal && (
          <ModalWithPreview
            onClose={() => setShowDemoModal(false)}
            onSubmit={() => {
              formProducts.setFieldValue('check3', true);
              setShowDemoModal(false);
            }}
            showSubmit={!check1FormProducts}
          />
        )}
        <img
          src={logoBig}
          onClick={handleCancel}
          alt=""
          className="z-2000 absolute left-1/2 top-[-184px] w-[215px] -translate-x-1/2 transform md:hidden"
        />
        {getContent()}
      </Modal>
    </>
  );
};

export default Login;
